<template>
  <div class="home grey lighten-2" :class="{'mobile': $vuetify.breakpoint.smAndDown}">

    <main-nav v-if="$vuetify.breakpoint.mdAndUp"></main-nav>

    <mobile-nav v-if="$vuetify.breakpoint.smAndDown"></mobile-nav>


    <div class="fill-height" :class="{'d-flex align-center justify-center': !isLoaded || loading}">

      <v-card class="welcome gradientBG ma-4 white--text pa-4 d-flex align-center flex-wrap" v-if="videos.length > 0 && isLoaded">
        <div class="col-12 col-md-9">
          <h1>YouTube + Tutorials = 🎉🎉🎉</h1>
          <p class="font-weight-medium">Pause, replay, and skip steps in tutorials to help you learn even better.</p>
        </div>
        <div class="col-12 col-md-3 img-container" @click="$router.push({name:'watch', params: {videoId: 'kQypVCmElDlaE4ys8Mml'}})">
          <v-icon size="60" color="white">play_arrow</v-icon>
          <v-img src="https://i.ytimg.com/vi/8TE_cjSFm2I/mqdefault.jpg" ></v-img>
        </div>
      </v-card>


      <template v-if="!isLoaded && loading">
        <v-card class="col-xs-10 col-md-4 elevation-0" color="transparent">
          <v-card-title class="grey--text">
            Loading Youtorials
          </v-card-title>
          <v-card-text>
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>

      </template>

      <!-- videos -->
      <video-cards :videos="videos" :loading="loading" v-if="videos.length > 0 && isLoaded"></video-cards>
      <!-- no videos -->
      <template v-else-if="videos.length == 0 && isLoaded">
        <div class="container">

          <v-card class="col-sm-10 col-12 col-md-4 elevation-0" color="grey lighten-2" >
            <v-card-title>
              Hmmm, nothing here..
            </v-card-title>
            <v-card-text>
              That's ok, create your own Youtorial to get started.
            </v-card-text>
            <v-card-actions class="align-center justify-center">
              <v-btn class="gradientBG" color="" block dark large @click="$router.push({name:'build'})">
                <v-icon class="mr-2">video_library</v-icon>Create</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </template>



    </div>

  </div>
</template>

<script>
const mobileNav =() => import("@/components/shared/mobile-nav.vue");
const mainNav = () => import("@/components/shared/nav.vue");
const videoCards = () => import("@/components/shared/video-cards.vue");
import { db } from '@/firebase';

export default {
  name: "home",
  metaInfo: {
    title: 'Youtorial: Home - Youtube Tutorials Made Awesome'
  },
  components:{
    mainNav,videoCards,mobileNav,
  },

  data(){
    return{
      videos: [],
      isLoaded: false,
      loading:true,
      userId:0,
    }
  },
  methods:{
    getVideos(){
      let vThis = this;
      vThis.$binding("videos", db.collection('videos').where('published','==', true))
      .then((res) => {
        vThis.isLoaded = true;
        vThis.loading = false;
      }).catch(err => {
        console.error(err)
      })
    },
  },
  mounted(){
    this.getVideos();
    if(this.$vuetify.breakpoint.mdAndUp){
      this.$store.dispatch('toggleDrawer', true);
    }
  }
  // firestore() {
  //   return {
  //     videos: db.collection('videos').where('userId','==', this.user)
  //   }
  // },
};
</script>


<style lang="scss">
.home{
  position: relative;
  height: 100vh;
  padding-top: 70px;
  padding-left: 280px;
//   background: rgba(75,192,200,1);
// background: -moz-linear-gradient(left, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// background: -webkit-gradient(left top, right top, color-stop(0%, rgba(75,192,200,1)), color-stop(50%, rgba(199,121,208,0.69)), color-stop(100%, rgba(254,171,94,0.37)));
// background: -webkit-linear-gradient(left, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// background: -o-linear-gradient(left, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// background: -ms-linear-gradient(left, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// background: linear-gradient(to right, rgba(75,192,200,1) 0%, rgba(199,121,208,0.69) 50%, rgba(254,171,94,0.37) 100%);
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4bc0c8', endColorstr='#feab5e', GradientType=1 );
  &.mobile{
    padding-top:0;
    padding-left: 0;
    .container{
      // padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
    }
  }
  .welcome{

    .img-container{
      position: relative;
      cursor: pointer;
      .v-icon{
        position: absolute;
        top: 32%;
        left: 40%;
        z-index: 99999;
        text-shadow: 2px 2px 2px #000;
      }
      img{
        max-width: 100%;
      }
    }
  }
}
</style>
